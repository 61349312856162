import getDayOfYear from 'date-fns/get_day_of_year';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { SET_OCCASIONS_DATA } from './constants';
import { formatOccasionDate } from './utils';

const initialState = {
  occasions: [],
  heroOccasionId: '',
  recipeCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OCCASIONS_DATA:
      return {
        ...state,
        occasions: action.occasions,
        heroOccasionId: action.heroOccasionId,
        recipeCount: action.recipeCount,
      };
    default:
      return state;
  }
};

export const selectUpcomingOccasions = ({ occasionsIndexReducer }, now = new Date()) =>
  sortBy(
    filter(occasionsIndexReducer.occasions, occasion => occasion.startDate),
    occasion => (occasion.endDate < getDayOfYear(now) ? occasion.endDate + 365 : occasion.endDate)
  );

export const selectOtherOccasions = ({ occasionsIndexReducer }) =>
  occasionsIndexReducer.occasions.filter(occasion => !occasion.startDate);

export const selectHeroOccasion = ({ occasionsIndexReducer }, now = new Date()) => {
  const heroOccasion = occasionsIndexReducer.occasions.find(
    occasion => occasion.id === occasionsIndexReducer.heroOccasionId
  );

  const occasionSubtitle = formatOccasionDate(heroOccasion.startDate, heroOccasion.endDate, now);
  return {
    id: heroOccasion.id,
    imageId: `/food/ic/food_16x9_{width}/occasions/${heroOccasion.id}_16x9.jpg`,
    footer: {
      text: `all recipes for ${heroOccasion.name}`,
      href: `/food/search?occasions=${heroOccasion.id}`,
      totalCount: occasionsIndexReducer.recipeCount,
    },
    occasionSubtitle,
    synopsis: heroOccasion.description,
    title: heroOccasion.name,
    type: 'Occasion',
    url: `/food/occasions/${heroOccasion.id}`,
  };
};

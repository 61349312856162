import React from 'react';
import PropTypes from 'prop-types';

import LdJson from './LdJson';

export const createVideoMarkup = ({ description, duration, image, title, updatedAt }) => ({
  description,
  duration,
  name: title,
  thumbnailUrl: [image.replace('{width}', 640)],
  uploadDate: updatedAt,
});

const VideoSchema = ({ description, duration, image, title, updatedAt }) => (
  <LdJson
    type="VideoObject"
    markup={createVideoMarkup({ description, duration, image, title, updatedAt })}
  />
);

VideoSchema.propTypes = {
  description: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

export default VideoSchema;

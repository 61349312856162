/* eslint-disable no-underscore-dangle, react/jsx-filename-extension */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';

import { configureStore } from './store';
import App from './App';

const store = configureStore(window.__reactInitialState__);

const googleCacheHost = 'webcache.googleusercontent.com';
const optimizelyHost = 'www.optimizelyedit.com';

const getGoogleCachePath = search => {
  const query = new URLSearchParams(search).get('q');
  return query.match(/(\/food.*?)\+?/)[1];
};

const getOptimizelyPath = pathname => {
  const decoded = decodeURIComponent(pathname.substr(1));
  return decoded.substr(decoded.indexOf('/food'));
};

const getPath = () => {
  switch (window.location.hostname) {
    case googleCacheHost:
      return getGoogleCachePath(window.location.search);
    case optimizelyHost:
      return getOptimizelyPath(window.location.pathname);
    default:
      return null;
  }
};

const WrappedApp = () =>
  getPath() ? (
    <Provider store={store}>
      <StaticRouter context={{}} location={getPath()}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </StaticRouter>
    </Provider>
  ) : (
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  );

loadableReady(() => {
  hydrate(<WrappedApp />, document.getElementById('main-content'));
});

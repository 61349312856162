import { SET_CHEF_DATA } from './constants';

const initialState = {
  id: '',
  azCounts: {},
  biog: '',
  activeTab: 'featured',
  displayName: '',
  items: [],
  links: [],
  page: 1,
  recipeTotalCount: undefined,
  recipeCountForLetter: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHEF_DATA:
      return {
        ...state,
        id: action.id,
        azCounts: action.azCounts,
        azPromos: action.azPromos,
        biog: action.biog,
        activeTab: action.activeTab,
        displayName: action.displayName,
        imageUrl: action.imageUrl,
        items: action.items,
        links: action.links,
        page: action.page,
        programmes: action.programmes,
        relatedRecipes: action.relatedRecipes,
        recipeTotalCount: action.recipeTotalCount,
        recipeCountForLetter: action.recipeCountForLetter,
        letter: action.letter,
      };
    default:
      return state;
  }
};

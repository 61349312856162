import { SET_PROGRAMMES_DATA } from './constants';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROGRAMMES_DATA:
      return {
        ...state,
        programmes: action.programmes,
      };
    default:
      return state;
  }
};

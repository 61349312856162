import { REDIRECT_TO_SIGN_IN, SET_PAGE_CONFIG, SET_IS_MOBILE } from './constants';

const initialState = {
  activePageId: '',
  background: '',
  isAMP: false,
  pageTitle: '',
  reverb: {},
  season: '',
  seasonalPromo: undefined,
  isMobile: false,
  uas: {
    url: '',
    apiKey: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REDIRECT_TO_SIGN_IN:
      window.location.href = state.signInUrl;
      return state;
    case SET_PAGE_CONFIG:
      return {
        ...state,
        ...action.config,
      };
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      };
    default:
      return state;
  }
};

import { SET_OCCASION_DATA } from './constants';

const initialState = {
  azCounts: {},
  baseUrl: '',
  ogImage: '',
  description: '',
  hero: undefined,
  id: '',
  name: '',
  totalCount: 0,
  relatedCollections: [],
  relatedMenus: [],
  relatedDishes: [],
  relatedIngredients: [],
  relatedRecipes: [],
  title: '',
  activeTab: 'featured',
  page: 1,
  letter: 'a',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OCCASION_DATA:
      return {
        ...state,
        azCounts: action.azCounts,
        baseUrl: action.baseUrl,
        ogImage: action.ogImage,
        description: action.description,
        hero: action.hero,
        id: action.id,
        name: action.name,
        totalCount: action.totalCount,
        relatedCollections: action.relatedCollections,
        relatedMenus: action.relatedMenus,
        relatedDishes: action.relatedDishes,
        relatedIngredients: action.relatedIngredients,
        relatedRecipes: action.relatedRecipes,
        title: action.title,
        activeTab: action.activeTab,
        page: action.page,
        letter: action.letter,
      };
    default:
      return state;
  }
};

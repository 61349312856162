import pick from 'lodash/pick';
import { SET_FOODS_DATA } from './constants';

const initialState = {
  title: '',
  description: '',
  hero: undefined,
  recipes: [],
  totalCount: 0,
  azCounts: {},
  relatedCollections: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FOODS_DATA:
      return {
        ...state,
        ...pick(action, [
          'buyersGuide',
          'description',
          'foodsId',
          'hero',
          'otherConsiderations',
          'preparation',
          'recipes',
          'totalCount',
          'azCounts',
          'relatedCollections',
          'storage',
          'title',
          'ogImage',
          'letter',
          'page',
          'pages',
        ]),
      };
    default:
      return state;
  }
};

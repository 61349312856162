import {
  SET_SEARCH_PAGE_DATA,
  SET_DISPLAY_NAMES_LOOKUP,
  SET_OPEN_FILTER,
  UPDATE_REQUEST,
  UPDATE_RESULTS,
  SET_PREV_FILTER,
  UPDATE_ERROR,
} from './constants';

const initialState = {
  searchTerm: '',
  page: 1,
  recipes: [],
  totalCount: 0,
  filterCounts: {},
  selected: {},
  displayNames: {},
  openFilter: '',
  prevOpenFilter: '',
  loading: false,
  error: false,
  latestSearch: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_PAGE_DATA:
      return {
        ...state,
        searchTerm: action.searchTerm,
        page: action.page,
        recipes: action.recipes,
        totalCount: action.totalCount,
        filterCounts: action.filterCounts,
        selected: action.selected,
      };
    case SET_DISPLAY_NAMES_LOOKUP:
      return {
        ...state,
        displayNames: action.displayNames,
      };
    case SET_OPEN_FILTER:
      return {
        ...state,
        openFilter: action.openFilter,
        prevOpenFilter: state.openFilter,
      };
    case SET_PREV_FILTER:
      return {
        ...state,
        prevOpenFilter: state.openFilter,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        page: action.page,
        selected: action.selected,
        loading: true,
        latestSearch: {
          searchTerm: action.searchTerm,
          page: action.page,
          selected: action.selected,
        },
      };
    case UPDATE_RESULTS:
      if (
        state.latestSearch.searchTerm === action.searchTerm &&
        state.latestSearch.page === action.page &&
        state.latestSearch.selected === action.selected
      ) {
        return {
          ...state,
          searchTerm: action.searchTerm,
          recipes: action.recipes,
          totalCount: action.totalCount,
          filterCounts: action.filterCounts,
          loading: false,
          error: false,
        };
      }
      return state;
    case UPDATE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import LdJson from './LdJson';

const Article = ({ title, metaDescription, modifiedDateTime }) => (
  <LdJson
    type="Article"
    markup={{
      description: metaDescription,
      headline: title,
      publisher: {
        '@type': 'Organization',
        name: 'BBC',
        logo: {
          '@type': 'ImageObject',
          url: 'https://static.bbci.co.uk/frameworks/barlesque/3.21.31/desktop/3.5/img/blq-blocks_grey_alpha.png',
          width: 84,
          height: 24,
        },
      },
      image: null,
      datePublished: format(new Date(modifiedDateTime), 'YYYY-MM-DD'),
    }}
  />
);

Article.propTypes = {
  title: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  modifiedDateTime: PropTypes.string.isRequired,
};

export default Article;

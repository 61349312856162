import merge from 'lodash/merge';
import {
  SET_ARTICLES_DATA,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAILURE,
} from './constants';

const initialState = {
  articles: [],
  loading: false,
  error: false,
  page: 1,
  totalCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ARTICLES_DATA:
      return {
        ...state,
        articles: action.articles,
        page: action.page,
        totalCount: action.totalCount,
      };
    case FETCH_ARTICLES_REQUEST:
      return merge({}, state, {
        loading: true,
        error: false,
      });
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.articles,
        loading: false,
        error: false,
        page: action.page,
        totalCount: action.totalCount,
      };
    case FETCH_ARTICLES_FAILURE:
      return merge({}, state, {
        loading: false,
        error: true,
      });
    default:
      return state;
  }
};

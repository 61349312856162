import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import articlePageReducer from './articlePage/reducer';
import articlesIndexReducer from './articles/reducer';
import chefReducer from './chef/reducer';
import collectionReducer from './collection/reducer';
import cuisineReducer from './cuisine/reducer';
import favouriteReducer from './favourite/reducer';
import foodsReducer from './foods/reducer';
import homeReducer from './home/reducer';
import idctaReducer from './generic/idcta/reducer';
import indexPageReducer from './indexPage/reducer';
import occasionsIndexReducer from './occasions/reducer';
import occasionReducer from './occasion/reducer';
import pageReducer from './page/reducer';
import programmesAzPageReducer from './programmes/atoz/reducer';
import programmesIndexReducer from './programmes/recent/reducer';
import programmeReducer from './programme/reducer';
import brandReducer from './programme/brand/reducer';
import episodeReducer from './programme/episode/reducer';
import ratingsReducer from './recipe/ratings/reducer';
import recipesIndexReducer from './recipes/reducer';
import recipeReducer from './recipe/reducer';
import schemaReducer from './schema/reducer';
import searchBarReducer from './searchBar/reducer';
import searchReducer from './search/reducer';
import shoppingListReducer from './shoppingList/reducer';
import seasonReducer from './season/reducer';
import techniquesIndexReducer from './techniques/reducer';
import techniqueReducer from './technique/reducer';
import azPageReducer from './azPage/reducer';

const rootReducer = combineReducers({
  chefReducer,
  collectionReducer,
  cuisineReducer,
  articlePageReducer,
  articlesIndexReducer,
  favouriteReducer,
  foodsReducer,
  homeReducer,
  idctaReducer,
  indexPageReducer,
  occasionsIndexReducer,
  occasionReducer,
  pageReducer,
  programmesAzPageReducer,
  programmesIndexReducer,
  programmeReducer,
  brandReducer,
  episodeReducer,
  recipesIndexReducer,
  recipeReducer,
  ratingsReducer,
  schemaReducer,
  seasonReducer,
  shoppingListReducer,
  searchBarReducer,
  searchReducer,
  techniquesIndexReducer,
  techniqueReducer,
  azPageReducer,
});

export const configureStore = (state = {}) =>
  createStore(rootReducer, state, applyMiddleware(thunk));

import { SET_SHOPPING_LIST_DATA } from './constants';

const initialState = {
  contentTitle: '',
  ingredients: [],
  recipeId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOPPING_LIST_DATA:
      return {
        ...state,
        contentTitle: action.contentTitle,
        ingredients: action.ingredients,
        recipeId: action.recipeId,
      };
    default:
      return state;
  }
};

import pick from 'lodash/pick';
import { SET_INDEX_PAGE_DATA } from './constants';

const initialState = {
  title: '',
  metaDescription: '',
  promoCollections: [],
  headerTitle: '',
  entity: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INDEX_PAGE_DATA:
      return {
        ...state,
        ...pick(action, ['hero', 'metaDescription', 'title', 'headerTitle', 'entity']),
        promoCollections: action.collections,
      };
    default:
      return state;
  }
};

import { SET_COLLECTION_DATA, SET_MENU_SHOPPING_LIST_DATA } from './constants';

const initialState = {
  id: '',
  description: '',
  title: '',
  occasionId: undefined,
  occasionName: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLECTION_DATA:
      return {
        ...state,
        id: action.id,
        description: action.description,
        hero: action.hero,
        imageUrl: action.imageUrl,
        title: action.title,
        occasionId: action.occasionId,
        occasionName: action.occasionName,
        recipes: action.recipes,
        relatedCollections: action.relatedCollections,
        relatedMenus: action.relatedMenus,
      };
    case SET_MENU_SHOPPING_LIST_DATA:
      return {
        ...state,
        id: action.id,
        title: action.title,
        description: action.description,
        recipes: action.recipes,
        ingredients: action.ingredients,
      };
    default:
      return state;
  }
};

import {
  HIDE_RATINGS_TOOLTIP,
  TOGGLE_RATINGS_TOOLTIP,
  TOGGLE_RATINGS_SIGNIN_TOOLTIP,
  RATING_LOADED,
  RATING_STATUS,
  SET_RATING,
} from './constants';

const initialState = {
  showRatingsTooltip: false,
  showSignInTooltip: false,
  status: RATING_STATUS.LOADING,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HIDE_RATINGS_TOOLTIP:
      return {
        ...state,
        showRatingsTooltip: false,
        showSignInTooltip: false,
      };
    case TOGGLE_RATINGS_TOOLTIP:
      return {
        ...state,
        showRatingsTooltip: !state.showRatingsTooltip,
      };
    case TOGGLE_RATINGS_SIGNIN_TOOLTIP:
      return {
        ...state,
        showSignInTooltip: !state.showSignInTooltip,
      };
    case RATING_LOADED:
      return { ...state, status: action.status };
    case SET_RATING:
      return { ...state, rating: action.rating };
    default:
      return state;
  }
};

import { SET_TECHNIQUES_DATA } from './constants';

const initialState = {
  techniquesByCategory: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TECHNIQUES_DATA:
      return {
        ...state,
        techniquesByCategory: action.techniquesByCategory,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

const LdJson = ({ markup, type }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': type,
        ...markup,
      }),
    }}
  />
);

LdJson.propTypes = {
  markup: PropTypes.object.isRequired, // eslint-disable-line
  type: PropTypes.string.isRequired,
};

export default LdJson;

export const SET_BRAND_DATA = 'SET_BRAND_DATA';
export const FETCH_LAST_ON_TV_REQUEST = 'FETCH_LAST_ON_TV_REQUEST';
export const FETCH_LAST_ON_TV_SUCCESS = 'FETCH_LAST_ON_TV_SUCCESS';
export const FETCH_LAST_ON_TV_FAILURE = 'FETCH_LAST_ON_TV_FAILURE';
export const FETCH_EPISODES_REQUEST = 'FETCH_EPISODES_REQUEST';
export const FETCH_EPISODES_SUCCESS = 'FETCH_EPISODES_SUCCESS';
export const FETCH_EPISODES_FAILURE = 'FETCH_EPISODES_FAILURE';
export const FETCH_RECIPES_REQUEST = 'FETCH_RECIPES_REQUEST';
export const FETCH_RECIPES_SUCCESS = 'FETCH_RECIPES_SUCCESS';
export const FETCH_RECIPES_FAILURE = 'FETCH_RECIPES_FAILURE';

import { SET_RECIPE_DATA } from './constants';

const initialState = {
  recipe: undefined,
  hasRecipeIsFrom: false,
  howToVideos: [],
  relatedRecipes: undefined,
  rating: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECIPE_DATA:
      return {
        ...state,
        recipe: action.recipe,
        hasRecipeIsFrom: action.hasRecipeIsFrom,
        howToVideos: action.howToVideos,
        relatedRecipes: action.relatedRecipes,
        rating: action.rating,
      };
    default:
      return state;
  }
};

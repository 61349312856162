import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import AmpAnalytics from './amp/AmpAnalytics';
import Chartbeat from './chartbeat';
import Schema from './schema/Schema';

if (process.env.CLIENT) {
  require('./App.scss'); // eslint-disable-line global-require
}

const HomePage = loadable(() => import('./home/Page'));
const ArticlePage = loadable(() => import('./articlePage/Page'));
const ArticlesPage = loadable(() => import('./articles/Page'));
const IndexPage = loadable(() => import('./indexPage/Page'));
const FavouritePage = loadable(() => import('./favourite/Page'));
const PageIndex = loadable(() => import('./page/PageIndex'));
const RecipePage = loadable(() => import('./recipe/Page'));
const RecipesPage = loadable(() => import('./recipes/Page'));
const OccasionsPage = loadable(() => import('./occasions/Page'));
const OccasionPage = loadable(() => import('./occasion/Page'));
const CollectionsPage = loadable(() => import('./collection/Page'));
const CollectionsAzPage = loadable(() => import('./collections/Page'));
const ChefPage = loadable(() => import('./chef/Page'));
const CuisinePage = loadable(() => import('./cuisine/Page'));
const MenuPage = loadable(() => import('./menu/Page'));
const MenuShoppingListPage = loadable(() => import('./menu/shoppingList/Page'));
const SeasonPage = loadable(() => import('./season/Page'));
const ProgrammesPage = loadable(() => import('./programmes/Page'));
const ProgrammePage = loadable(() => import('./programme/Page'));
const ShoppingList = loadable(() => import('./shoppingList/Page'));
const TechniquesPage = loadable(() => import('./techniques/Page'));
const TechniquePage = loadable(() => import('./technique/Page'));
const FoodsPage = loadable(() => import('./foods/Page'));
const SearchPage = loadable(() => import('./search/Page'));
const AzPage = loadable(() => import('./azPage/Page'));

const getPageComponent = pageType => {
  switch (pageType) {
    case 'index':
      return IndexPage;
    case 'article-page':
      return ArticlePage;
    default:
      return null;
  }
};

const wrapPageIndex = Wrapped => props => (
  <PageIndex>
    <Wrapped {...props} />
  </PageIndex>
);

export const App = ({
  activePageId,
  azEntities,
  canonical,
  isAMP,
  pageType,
  thrownErrorStatus,
}) => (
  <>
    <Helmet
      encodeSpecialCharacters={false}
      titleTemplate={activePageId === 'home' ? 'BBC Food - %s' : '%s - BBC Food'}
    >
      <meta name="description" content="BBC Food" />
      <meta property="og:url" content={canonical} />
      <link rel="canonical" href={canonical} />
    </Helmet>
    <Switch>
      {thrownErrorStatus === 404 && <Route component={wrapPageIndex(RecipesPage)} />}
      <Route exact path="/food/recipes/:id/shopping-list" component={ShoppingList} />
      <Route exact path="/food" component={wrapPageIndex(HomePage)} />
      <Route exact path="/food/search" component={wrapPageIndex(SearchPage)} />
      <Route exact path="/food/recipes/:id/amp" component={wrapPageIndex(RecipePage)} />
      <Route exact path="/food/recipes/:id/preview" component={wrapPageIndex(RecipePage)} />
      <Route exact path="/food/recipes/:id" component={wrapPageIndex(RecipePage)} />
      <Route exact path="/food/recipes" component={wrapPageIndex(RecipesPage)} />
      <Route
        exact
        path={`/food/:entity(${azEntities.join('|')})/a-z/:letter/:page`}
        component={wrapPageIndex(AzPage)}
      />
      <Route exact path="/food/chefs/:id/a-z/:letter/:page" component={wrapPageIndex(ChefPage)} />
      <Route exact path="/food/occasions/:id" component={wrapPageIndex(OccasionPage)} />
      <Route exact path="/food/collections/:id" component={wrapPageIndex(CollectionsPage)} />
      <Route
        exact
        path={['/food/collections', '/food/collections/a-z/:letter/:page']}
        component={wrapPageIndex(CollectionsAzPage)}
      />
      <Route exact path="/food/budget" component={wrapPageIndex(getPageComponent(pageType))} />
      <Route exact path="/food/budget/:id" component={wrapPageIndex(getPageComponent(pageType))} />
      <Route exact path="/food/chefs/:id" component={wrapPageIndex(ChefPage)} />
      <Route exact path="/food/cuisines/:id" component={wrapPageIndex(CuisinePage)} />
      <Route
        exact
        path="/food/cuisines/:id/a-z/:letter/:page"
        component={wrapPageIndex(CuisinePage)}
      />
      <Route
        exact
        path="/food/menus/:id/shopping-list"
        component={wrapPageIndex(MenuShoppingListPage)}
      />
      <Route exact path="/food/menus/:id" component={wrapPageIndex(MenuPage)} />
      <Route exact path="/food/seasons/:id" component={wrapPageIndex(SeasonPage)} />
      <Route
        exact
        path="/food/seasons/:id/a-z/:letter/:page"
        component={wrapPageIndex(SeasonPage)}
      />
      <Route exact path="/food/techniques/:id" component={wrapPageIndex(TechniquePage)} />
      <Route
        exact
        path="/food/(ingredients|seasons|dishes|cuisines)"
        component={wrapPageIndex(IndexPage)}
      />
      <Route exact path="/food/articles" component={wrapPageIndex(ArticlesPage)} />
      <Route exact path="/food/articles/:id" component={wrapPageIndex(ArticlePage)} />
      <Route exact path="/food/diets/:id?" component={wrapPageIndex(getPageComponent(pageType))} />
      <Route
        exact
        path="/food/(chefs|faqs)"
        component={wrapPageIndex(getPageComponent(pageType))}
      />
      <Route exact path="/food/occasions" component={wrapPageIndex(OccasionsPage)} />
      <Route
        exact
        path="/food/occasions/:id/a-z/:letter/:page"
        component={wrapPageIndex(OccasionPage)}
      />
      <Route path="/food/programmes/a-z" component={wrapPageIndex(ProgrammesPage)} />
      <Route path="/food/programmes/:id" component={wrapPageIndex(ProgrammePage)} />
      <Route path="/food/programmes" component={wrapPageIndex(ProgrammesPage)} />
      <Route exact path="/food/techniques" component={wrapPageIndex(TechniquesPage)} />
      <Route
        exact
        path="/food/techniques/:id/a-z/:letter/:page"
        component={wrapPageIndex(TechniquePage)}
      />
      <Route exact path="/food/preview/articles/:id" component={wrapPageIndex(ArticlePage)} />
      <Route
        exact
        path="/food/preview/diets/:id?"
        component={wrapPageIndex(getPageComponent(pageType))}
      />
      <Route
        exact
        path="/food/preview/(chefs|faqs)"
        component={wrapPageIndex(getPageComponent(pageType))}
      />
      <Route exact path="/food/preview/index/:id" component={wrapPageIndex(IndexPage)} />
      <Route exact path="/food/my/favourites" component={wrapPageIndex(FavouritePage)} />
      <Route path="/food/:id" component={wrapPageIndex(FoodsPage)} />
    </Switch>
    <Chartbeat />
    {isAMP && <AmpAnalytics />}
    <Schema />
  </>
);

App.propTypes = {
  activePageId: PropTypes.string,
  azEntities: PropTypes.arrayOf(PropTypes.string).isRequired,
  canonical: PropTypes.string.isRequired,
  isAMP: PropTypes.bool,
  pageType: PropTypes.string,
  thrownErrorStatus: PropTypes.number,
};

App.defaultProps = {
  activePageId: undefined,
  isAMP: false,
  pageType: undefined,
  thrownErrorStatus: undefined,
};

export default connect(state => ({
  activePageId: state.pageReducer.activePageId,
  azEntities: state.pageReducer.azEntities,
  canonical: state.pageReducer.canonical,
  isAMP: state.pageReducer.isAMP,
  pageType: state.pageReducer.pageType,
  thrownErrorStatus: state.pageReducer.thrownErrorStatus,
}))(App);

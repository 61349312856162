export const HIDE_RATINGS_TOOLTIP = 'HIDE_RATINGS_TOOLTIP';
export const TOGGLE_RATINGS_TOOLTIP = 'TOGGLE_RATINGS_TOOLTIP';
export const TOGGLE_RATINGS_SIGNIN_TOOLTIP = 'TOGGLE_RATINGS_SIGNIN_TOOLTIP';
export const RATING_LOADED = 'RATING_LOADED';
export const SET_RATING = 'SET_RATING';

export const RATING_STATUS = {
  RATED: 'RATED',
  UNRATED: 'UNRATED',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

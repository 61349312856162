import { SET_CUISINE_DATA } from './constants';

const initialState = {
  azCounts: {},
  totalCount: 0,
  activeTab: 'featured',
  id: '',
  letter: 'a',
  page: 1,
  title: '',
  description: '',
  recipeCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CUISINE_DATA:
      return {
        ...state,
        azCounts: action.azCounts,
        totalCount: action.totalCount,
        activeTab: action.activeTab,
        id: action.id,
        imageUrl: action.imageUrl,
        letter: action.letter,
        page: action.page,
        title: action.title,
        description: action.description,
        relatedRecipes: action.relatedRecipes,
        relatedDishes: action.relatedDishes,
        relatedIngredients: action.relatedIngredients,
        recipeCount: action.recipeCount,
      };
    default:
      return state;
  }
};

export const FAVOURITE_RECIPE_STATUS = {
  ADDED: 'ADDED',
  ADDING: 'ADDING',
  ERROR_ADDING: 'ERROR_ADDING',
  LOADING: 'LOADING',
  FAVOURITED: 'FAVOURITED',
  NOT_FAVOURITED: 'NOT_FAVOURITED',
};
export const REMOVE_FAVOURITE_RECIPE = 'REMOVE_FAVOURITE_RECIPE';
export const SET_FAVOURITE_RECIPES = 'SET_FAVOURITE_RECIPES';
export const SET_PAGE_STATE = 'SET_PAGE_STATE';
export const SET_RECIPE_COUNT_PER_PAGE = 'SET_RECIPE_COUNT_PER_PAGE';
export const SET_REMOVE_BTN_STATE = 'SET_REMOVE_BTN_STATE';
export const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION';
export const UPDATE_ADD_TO_FAVOURITES_BUTTON = 'UPDATE_ADD_TO_FAVOURITES_BUTTON';
export const SHOW_FAVOURITES_TOOLTIP = 'SHOW_FAVOURITES_TOOLTIP';
export const HIDE_FAVOURITES_TOOLTIP = 'HIDE_FAVOURITES_TOOLTIP';

import React from 'react';
import PropTypes from 'prop-types';

import LdJson from './LdJson';

const IndexRecipe = ({ title, description, image }) => {
  return (
    <LdJson
      type="Recipe"
      markup={{
        author: {
          '@type': 'Person',
          name: 'BBC Food team',
        },
        name: title,
        description,
        image,
        keywords: '',
      }}
    />
  );
};

IndexRecipe.defaultProps = {
  image: undefined,
};

IndexRecipe.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default IndexRecipe;

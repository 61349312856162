import {
  CLEAR_SEARCH_RESULTS,
  UPDATE_SEARCH_RESULTS,
  UPDATE_SEARCH_TERM,
  UPDATE_DISPLAY_SEARCH_TERM,
  SELECT_SEARCH_RESULT,
  CLEAR_SELECTED_SEARCH_RESULT,
} from './constants';

const initialState = {
  shouldSubmit: false,
  selectedSearchResultIndex: undefined,
  searchResults: [],
  searchTerm: '',
  displaySearchTerm: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: [],
        selectedSearchResultIndex: undefined,
      };
    case UPDATE_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    case UPDATE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
        shouldSubmit: action.shouldSubmit,
      };
    case UPDATE_DISPLAY_SEARCH_TERM:
      return {
        ...state,
        displaySearchTerm: action.searchTerm,
      };
    case SELECT_SEARCH_RESULT:
      return {
        ...state,
        selectedSearchResultIndex: action.index,
      };
    case CLEAR_SELECTED_SEARCH_RESULT:
      return {
        ...state,
        selectedSearchResultIndex: undefined,
      };
    default:
      return state;
  }
};

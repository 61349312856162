import pick from 'lodash/pick';
import { SET_PROGRAMMES_AZ_PAGE_DATA } from './constants';

const initialState = {
  letter: '',
  page: 1,
  pages: 1,
  promos: [],
  totalCount: 0,
  azCounts: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROGRAMMES_AZ_PAGE_DATA:
      return {
        ...state,
        ...pick(action, ['letter', 'page', 'pages', 'promos', 'totalCount', 'azCounts']),
      };
    default:
      return state;
  }
};

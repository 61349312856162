import { SET_SEASON_DATA } from './constants';

const initialState = {
  azCounts: {},
  activeTab: 'featured',
  baseUrl: '',
  hero: undefined,
  id: '',
  name: '',
  description: '',
  relatedRecipes: [],
  ogImage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEASON_DATA:
      return {
        ...state,
        baseUrl: action.baseUrl,
        hero: action.hero,
        id: action.id,
        name: action.name,
        description: action.description,
        relatedRecipes: action.relatedRecipes,
        ogImage: action.ogImage,
        seasonalIngredients: action.seasonalIngredients,
        azCounts: action.azCounts,
        activeTab: action.activeTab,
        letter: action.letter,
        page: action.page,
        totalCount: action.totalCount,
      };
    default:
      return state;
  }
};

import pick from 'lodash/pick';
import { SET_RECIPES_INDEX_DATA } from './constants';

const initialState = {
  allTimeFavDishes: [],
  hero: undefined,
  ingredients: [],
  recipeCollections: [],
  thrownErrorFrom: '',
  thrownErrorStatus: 500,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RECIPES_INDEX_DATA:
      return {
        ...state,
        ...pick(action, [
          'allTimeFavDishes',
          'hero',
          'ingredients',
          'totalIngredients',
          'recipeCollections',
          'thrownErrorFrom',
          'thrownErrorStatus',
        ]),
      };
    default:
      return state;
  }
};

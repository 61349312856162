import merge from 'lodash/merge';
import {
  SET_BRAND_DATA,
  FETCH_LAST_ON_TV_REQUEST,
  FETCH_LAST_ON_TV_SUCCESS,
  FETCH_LAST_ON_TV_FAILURE,
  FETCH_EPISODES_REQUEST,
  FETCH_EPISODES_SUCCESS,
  FETCH_EPISODES_FAILURE,
  FETCH_RECIPES_REQUEST,
  FETCH_RECIPES_SUCCESS,
  FETCH_RECIPES_FAILURE,
} from './constants';

const initialState = {
  tabs: {
    lastOnTv: true,
    recipes: true,
    episodes: true,
  },
  recentEpisodes: {
    recentEpisodes: [],
    loading: false,
    error: false,
  },
  episodes: {
    episodes: [],
    loading: false,
    error: false,
    page: 1,
    totalCount: 0,
  },
  recipes: {
    recipes: [],
    loading: false,
    error: false,
    page: 1,
    totalCount: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BRAND_DATA:
      return {
        ...state,
        tabs: {
          lastOnTv: action.tabs.lastOnTv,
          recipes: action.tabs.recipes,
          episodes: action.tabs.episodes,
        },
        id: action.id,
        title: action.title,
        description: action.description,
        image: action.image,
      };
    case FETCH_LAST_ON_TV_REQUEST:
      return merge({}, state, {
        recentEpisodes: {
          loading: true,
          error: false,
        },
      });
    case FETCH_LAST_ON_TV_SUCCESS:
      return {
        ...state,
        recentEpisodes: {
          recentEpisodes: action.recentEpisodes,
          loading: false,
          error: false,
        },
      };
    case FETCH_LAST_ON_TV_FAILURE:
      return merge({}, state, {
        recentEpisodes: {
          loading: false,
          error: true,
        },
      });
    case FETCH_EPISODES_REQUEST:
      return merge({}, state, {
        episodes: {
          loading: true,
          error: false,
        },
      });
    case FETCH_EPISODES_SUCCESS:
      return {
        ...state,
        episodes: {
          episodes: action.episodes,
          loading: false,
          error: false,
          page: action.page,
          totalCount: action.totalCount,
        },
      };
    case FETCH_EPISODES_FAILURE:
      return merge({}, state, {
        episodes: {
          loading: false,
          error: true,
        },
      });
    case FETCH_RECIPES_REQUEST:
      return merge({}, state, {
        recipes: {
          loading: true,
          error: false,
        },
      });
    case FETCH_RECIPES_SUCCESS:
      return {
        ...state,
        recipes: {
          recipes: action.recipes,
          loading: false,
          error: false,
          page: action.page,
          totalCount: action.totalCount,
        },
      };
    case FETCH_RECIPES_FAILURE:
      return merge({}, state, {
        recipes: {
          loading: false,
          error: true,
        },
      });
    default:
      return state;
  }
};

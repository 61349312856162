import PropTypes from 'prop-types';

export const videoShape = {
  additionalUIOptions: PropTypes.object,
  brandTitle: PropTypes.string,
  description: PropTypes.string,
  counterName: PropTypes.string,
  duration: PropTypes.string,
  durationSeconds: PropTypes.number,
  ichefBasePath: PropTypes.string,
  masterBrandId: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  versionPid: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
};

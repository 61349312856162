/* eslint-disable camelcase, no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import { recipeShape } from '../recipe/shapes';

export class Chartbeat extends Component {
  componentDidMount() {
    const { _cbq, _sf_async_config } = this.getChartbeatConfig();
    window._cbq = _cbq;
    window._sf_async_config = _sf_async_config;

    const e = document.createElement('script');
    e.setAttribute('language', 'javascript');
    e.setAttribute('type', 'text/javascript');
    e.setAttribute('src', 'https://static.chartbeat.com/js/chartbeat.js');
    document.body.appendChild(e);
  }

  getChartbeatConfig() {
    const { chartbeat, recipe } = this.props;
    const { domain, section, uid } = chartbeat;

    return {
      _sf_async_config: {
        authors: get(recipe, 'chefDetails.name') || '',
        domain,
        sections: section,
        uid,
        useCanonical: true,
      },
    };
  }

  render() {
    const { isAMP } = this.props;
    const { _sf_async_config } = this.getChartbeatConfig();

    return (
      <div>
        {isAMP && (
          <amp-analytics type="chartbeat">
            <script
              type="application/json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({ vars: _sf_async_config }),
              }}
            />
          </amp-analytics>
        )}
      </div>
    );
  }
}

Chartbeat.defaultProps = {
  recipe: undefined,
};

Chartbeat.propTypes = {
  chartbeat: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }).isRequired,
  isAMP: PropTypes.bool.isRequired,
  recipe: PropTypes.shape(recipeShape),
};

export default connect(state => ({
  chartbeat: state.pageReducer.chartbeat,
  isAMP: state.pageReducer.isAMP,
  recipe: state.recipeReducer.recipe,
}))(Chartbeat);

import { SET_HOME_DATA } from './constants';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOME_DATA:
      return {
        ...state,
        inSeasonData: action.inSeasonData,
        meetTheChefs: action.meetTheChefs,
        promos: action.promos,
        recipesFromProgrammes: action.recipesFromProgrammes,
        specialDiets: action.specialDiets,
        techniques: action.techniques,
        techniquesTotalCount: action.techniquesTotalCount,
        topRecipes: action.topRecipes,
        articles: action.articles,
        articlesTotalCount: action.articlesTotalCount,
      };
    default:
      return state;
  }
};

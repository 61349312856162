import React from 'react';
import PropTypes from 'prop-types';

import LdJson from './LdJson';

const Website = ({ baseUrl }) => (
  <LdJson
    type="WebSite"
    markup={{
      url: `${baseUrl}/food`,
      potentialAction: [
        {
          '@type': 'SearchAction',
          target: `${baseUrl}/food/search?q={query}`,
          'query-input': 'required name=query',
        },
      ],
    }}
  />
);

Website.propTypes = {
  baseUrl: PropTypes.string.isRequired,
};

export default Website;

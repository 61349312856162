import PropTypes from 'prop-types';
import { videoShape } from '../generic/shapes';

export const dietsShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export const metadataShape = {
  prepTime: PropTypes.string,
  prepTimeMeta: PropTypes.string,
  cookingTime: PropTypes.string,
  cookTimeMeta: PropTypes.string,
  serving: PropTypes.string,
};

export const programmeShape = {
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export const chefDetailsShape = {
  hasImage: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  programme: PropTypes.shape(programmeShape),
};

export const recipeIsPartOfMenuShape = {
  id: PropTypes.string.isRequired,
  hasImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  keyRecipe: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hasImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  description: PropTypes.string.isRequired,
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      course: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
      id: PropTypes.string.isRequired,
      hasImage: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      prepTime: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      sequence: PropTypes.number.isRequired,
      cookingTime: PropTypes.string.isRequired,
      chef: PropTypes.shape({
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
      }),
    })
  ),
  name: PropTypes.string,
};

export const methodsShape = PropTypes.shape({
  text: PropTypes.string.isRequired,
});

export const stagesShape = PropTypes.shape({
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  title: PropTypes.string,
});

export const recipeShape = {
  chefDetails: PropTypes.shape(chefDetailsShape),
  metadata: PropTypes.shape(metadataShape),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  diets: PropTypes.arrayOf(dietsShape),
  description: PropTypes.string,
  hasImage: PropTypes.bool,
  id: PropTypes.string,
  methods: PropTypes.arrayOf(methodsShape),
  placeholder: PropTypes.string,
  poster: PropTypes.string,
  programmeId: PropTypes.string,
  programmeVpid: PropTypes.string,
  recipeIsPartOfMenu: PropTypes.shape(recipeIsPartOfMenuShape),
  stages: PropTypes.arrayOf(stagesShape),
  title: PropTypes.string,
  video: PropTypes.shape(videoShape),
};

export const howToVideoShape = {
  id: PropTypes.string,
  placeholderImageUrl: PropTypes.string,
  programmeClip: PropTypes.string,
  title: PropTypes.string,
  vpid: PropTypes.string,
};

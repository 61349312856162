import { ADD_PRIMARY_IMAGE_URL, ADD_PROMO, ADD_VIDEO, ADD_INDEX_RECIPE } from './constants';

const initialState = {
  promos: {},
  videos: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRIMARY_IMAGE_URL:
      return {
        ...state,
        primaryImageUrl: action.primaryImageUrl,
      };
    case ADD_PROMO:
      return {
        ...state,
        promos: {
          ...state.promos,
          [action.promo.id]: action.promo,
        },
      };
    case ADD_VIDEO:
      return {
        ...state,
        videos: {
          ...state.videos,
          [action.video.versionPid]: action.video,
        },
      };
    case ADD_INDEX_RECIPE:
      return {
        ...state,
        recipe: action.recipe,
      };
    default:
      return state;
  }
};

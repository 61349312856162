import React from 'react';
import PropTypes from 'prop-types';

import LdJson from './LdJson';

const Webpage = ({ ichefBasePath, primaryImageUrl }) => {
  const imageUrl = /^http/.test(primaryImageUrl)
    ? primaryImageUrl
    : `${ichefBasePath}${primaryImageUrl}`;

  return (
    <LdJson
      type="WebPage"
      markup={{
        primaryImageOfPage: {
          '@type': 'ImageObject',
          url: imageUrl.replace('{width}', '832'),
        },
      }}
    />
  );
};

Webpage.propTypes = {
  ichefBasePath: PropTypes.string.isRequired,
  primaryImageUrl: PropTypes.string.isRequired,
};

export default Webpage;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { URLSearchParams } from 'url';

const analyticsUrl = 'https://sa.bbc.co.uk/bbc/bbc/s';

export const AmpAnalytics = ({ reverb }) => {
  const analyticsQueryParams = {
    app_type: 'amp',
    bbc_site: 'food',
    ml_name: 'amp-pixel',
    ml_version: 'none',
    language: 'en',
    ns_jspageurl: 'SOURCE_URL',
    ns_referrer: 'DOCUMENT_REFERRER',
  };

  return (
    <>
      <amp-pixel
        layout="fixed"
        src={`${analyticsUrl}?${new URLSearchParams(analyticsQueryParams)}`}
      />
      {Object.keys(reverb).length > 0 && (
        <amp-analytics type="atinternet" id="atinternet">
          <script
            type="application/json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                vars: {
                  site: reverb.siteId,
                  log: reverb.log,
                  domain: reverb.domain,
                  title: `${reverb.section}::${reverb.name}`,
                  level2: '11',
                },
                triggers: {
                  trackPageview: {
                    on: 'visible',
                    request: 'pageview',
                    extraUrlParams: {
                      x1: `[${reverb.contentId}]`,
                      x2: '[amp]',
                      x3: '[bbc_website]',
                      x4: '[en]',
                      x5: '[${sourceUrl}]', // eslint-disable-line no-template-curly-in-string
                      x6: '[${documentReferrer}]', // eslint-disable-line no-template-curly-in-string
                      x7: `[${reverb.contentType}]`,
                      x8: '[amp-analytics-0.1]',
                    },
                  },
                },
              }),
            }}
          />
        </amp-analytics>
      )}
    </>
  );
};

AmpAnalytics.defaultProps = {
  reverb: {},
};

AmpAnalytics.propTypes = {
  reverb: PropTypes.object, // eslint-disable-line
};

export default connect(state => ({
  reverb: state.pageReducer.reverb,
}))(AmpAnalytics);

import React from 'react';
import { connect } from 'react-redux';

import Recipe from './Recipe';
import RecipeCarousel from './RecipeCarousel';
import Article from './Article';
import Webpage from './Webpage';
import Website from './Website';
import VideoSchema from './VideoSchema';
import IndexRecipe from './IndexRecipe';

export default connect(({ pageReducer, recipeReducer, schemaReducer, articlePageReducer }) => ({
  article: articlePageReducer,
  foodImagesPath: pageReducer.foodImagesPath,
  ichefBasePath: pageReducer.ichefBasePath,
  primaryImageUrl: schemaReducer.primaryImageUrl,
  promos: schemaReducer.promos,
  rating: recipeReducer.rating,
  recipe: recipeReducer.recipe,
  videos: schemaReducer.videos,
  indexRecipe: schemaReducer.recipe,
}))(
  ({
    article,
    foodImagesPath,
    ichefBasePath,
    primaryImageUrl,
    promos,
    rating,
    recipe,
    videos,
    indexRecipe,
  }) => (
    <>
      {recipe && <Recipe foodImagesPath={foodImagesPath} rating={rating} recipe={recipe} />}

      {indexRecipe && (
        <IndexRecipe
          title={indexRecipe.title}
          description={indexRecipe.description}
          image={indexRecipe.image}
        />
      )}

      {!indexRecipe && (
        <RecipeCarousel baseUrl="https://www.bbc.co.uk" promos={Object.values(promos)} />
      )}
      {article.title && (
        <Article
          title={article.title}
          metaDescription={article.metaDescription}
          modifiedDateTime={article.modifiedDateTime}
        />
      )}
      {primaryImageUrl && (
        <Webpage ichefBasePath={ichefBasePath} primaryImageUrl={primaryImageUrl} />
      )}
      <Website baseUrl="https://www.bbc.co.uk" />
      {Object.values(videos).map(video => (
        <VideoSchema key={video.versionPid} {...video} />
      ))}
    </>
  )
);

import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import LdJson from './LdJson';
import { recipeShape } from '../recipe/shapes';
import { createVideoMarkup } from './VideoSchema';

const getIngredients = stages => compact(flatten((stages || []).map(stage => stage.ingredients)));

const formatCollectionTitle = ({ title }) =>
  title.replace(/\srecipes$|\srecipes\sand\s.+$/, '').toLowerCase();

const getKeywords = recipe => {
  const seasonMap = {
    january: 'winter',
    february: 'winter',
    march: 'spring',
    april: 'spring',
    may: 'spring',
    june: 'summer',
    july: 'summer',
    august: 'summer',
    september: 'autumn',
    october: 'autumn',
    november: 'autumn',
    december: 'winter',
  };
  const quickKeywords =
    recipe.metadata.prepTime === 'less than 30 mins' &&
    !['30 mins to 1 hour', '1 to 2 hours', 'over 2 hours'].includes(recipe.metadata.cookingTime)
      ? ['quick']
      : [];
  const collectionKeywords = (recipe.collections || []).map(formatCollectionTitle);
  const occasionKeywords = (recipe.occasions || []).map(formatCollectionTitle);
  const foodKeywords = recipe.food ? [recipe.food.title] : [];
  const ingredientKeywords = compact(
    flatten(getIngredients(recipe.stages).map(({ foods }) => foods))
  )
    .filter(ing => ing.isKeyIngredient === 'true')
    .map(({ title }) => title);
  const dietKeywords = (recipe.diets || []).map(({ title }) => title.replace(/[-_]/g, ' '));
  const seasonKeywords = uniq(compact((recipe.seasons || []).map(({ id }) => seasonMap[id])));
  const programmeKeywords = compact([recipe.programmeTopLevel]).map(({ title }) => title);
  return [
    ...quickKeywords,
    ...collectionKeywords,
    ...occasionKeywords,
    ...foodKeywords,
    ...ingredientKeywords,
    ...dietKeywords,
    ...seasonKeywords,
    ...programmeKeywords,
  ].join(', ');
};

const getNutritionalData = nutrition => ({
  calories: `${nutrition.kcal}kcal`,
  carbohydrateContent: nutrition.carbohydrate,
  fatContent: nutrition.fat,
  fiberContent: nutrition.fibre,
  proteinContent: nutrition.protein,
  saturatedFatContent: nutrition.saturates,
  sugarContent: nutrition.sugars,
});

const dietMap = {
  dairy_free: 'http://schema.org/LowLactoseDiet',
  gluten_free: 'http://schema.org/GlutenFreeDiet',
  healthy: 'http://schema.org/LowCalorieDiet',
  vegan: 'http://schema.org/VeganDiet',
  vegetarian: 'http://schema.org/VegetarianDiet',
};

const Recipe = ({ foodImagesPath, rating, recipe }) => {
  const ingredients = getIngredients(recipe.stagesWithoutLinks).map(ingredient => ingredient.text);
  const image = recipe.hasImage
    ? `${foodImagesPath}/food/recipes/${recipe.id}_16x9.jpg`
    : recipe.video && recipe.video.image.replace('{width}', 640);
  return (
    <LdJson
      type="Recipe"
      markup={{
        ...(rating &&
          rating.total > 0 && {
            aggregateRating: {
              ratingCount: rating.total,
              ratingValue: rating.value,
            },
          }),
        author: {
          '@type': get(recipe, 'chefDetails.name') ? 'Person' : 'Organization',
          name: get(recipe, 'chefDetails.name') || 'BBC Food',
        },
        cookTime: get(recipe, 'metadata.cookTimeMeta'),
        description: recipe.description,
        image: image && [image],
        keywords: getKeywords(recipe),
        name: recipe.title,
        nutrition: recipe.nutrition ? getNutritionalData(recipe.nutrition) : undefined,
        prepTime: get(recipe, 'metadata.prepTimeMeta'),
        recipeCategory: get(recipe, 'course.title'),
        recipeCuisine: get(recipe, 'cuisine.title'),
        recipeIngredient: ingredients,
        recipeInstructions: (recipe.methods || []).map(method => method.text),
        recipeYield: get(recipe, 'metadata.serving'),
        suitableForDiet: compact(recipe.diets.map(({ id }) => dietMap[id])),
        video: recipe.video
          ? [createVideoMarkup({ ...recipe.video, ...(image ? { image } : {}) })]
          : undefined,
      }}
    />
  );
};

Recipe.defaultProps = {
  rating: undefined,
  recipe: undefined,
};

Recipe.propTypes = {
  foodImagesPath: PropTypes.string.isRequired,
  rating: PropTypes.shape({
    total: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
  recipe: PropTypes.shape(recipeShape),
};

export default Recipe;

import { SET_TECHNIQUE_DATA } from './constants';

const initialState = {
  id: '',
  title: '',
  description: '',
  category: '',
  keyRecipe: undefined,
  equipment: '',
  difficulty: '',
  programmeVpid: '',
  relatedRecipes: [],
  azCounts: {},
  activeTab: 'featured',
  letter: '',
  page: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TECHNIQUE_DATA:
      return {
        ...state,
        id: action.id,
        title: action.title,
        description: action.description,
        category: action.category,
        keyRecipe: action.keyRecipe,
        equipment: action.equipment,
        difficulty: action.difficulty,
        relatedRecipes: action.relatedRecipes,
        video: action.video,
        azCounts: action.azCounts,
        activeTab: action.activeTab,
        letter: action.letter,
        page: action.page,
        totalCount: action.totalCount,
      };
    default:
      return state;
  }
};

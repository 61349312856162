import {
  REMOVE_FAVOURITE_RECIPE,
  SET_FAVOURITE_RECIPES,
  SET_PAGE_STATE,
  SET_RECIPE_COUNT_PER_PAGE,
  SET_REMOVE_BTN_STATE,
  TOGGLE_CONFIRMATION,
  UPDATE_ADD_TO_FAVOURITES_BUTTON,
  SHOW_FAVOURITES_TOOLTIP,
  HIDE_FAVOURITES_TOOLTIP,
} from './constants';

const initialState = {
  confirmationList: [],
  page: 1,
  favouriteRecipes: [],
  favouriteRecipeStatus: undefined,
  pageState: 'loading',
  recipeCountPerPage: 24,
  removeButtonState: {},
  totalRecipes: 0,
  shouldShowFavouritesTooltip: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FAVOURITES_TOOLTIP:
      return {
        ...state,
        shouldShowFavouritesTooltip: true,
      };
    case HIDE_FAVOURITES_TOOLTIP:
      return {
        ...state,
        shouldShowFavouritesTooltip: false,
      };
    case SET_FAVOURITE_RECIPES:
      return {
        ...state,
        favouriteRecipes: action.favouriteRecipes,
        totalRecipes: action.total,
        pageState: 'loaded',
        page: action.page,
      };
    case TOGGLE_CONFIRMATION:
      return {
        ...state,
        confirmationList: state.confirmationList.some(id => id === action.id)
          ? state.confirmationList.filter(id => id !== action.id)
          : [...state.confirmationList, action.id],
      };
    case REMOVE_FAVOURITE_RECIPE:
      return {
        ...state,
        favouriteRecipes: state.favouriteRecipes.filter(recipe => recipe.id !== action.id),
      };
    case SET_REMOVE_BTN_STATE:
      return {
        ...state,
        removeButtonState: { ...state.removeButtonState, [action.id]: action.state },
      };
    case SET_PAGE_STATE:
      return {
        ...state,
        pageState: action.state,
      };
    case SET_RECIPE_COUNT_PER_PAGE:
      return { ...state, recipeCountPerPage: action.count };
    case UPDATE_ADD_TO_FAVOURITES_BUTTON:
      return { ...state, favouriteRecipeStatus: action.favouriteRecipeStatus };
    default:
      return state;
  }
};

export const selectTotalPage = ({ favouriteReducer }) =>
  Math.ceil(favouriteReducer.totalRecipes / favouriteReducer.recipeCountPerPage);

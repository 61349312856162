import { IDCTA_INIT_RENDER, IDCTA_FAIL_RENDER, IDCTA_FLAGPOLE_RED } from './constants';

export default (state = {}, action) => {
  switch (action.type) {
    case IDCTA_INIT_RENDER:
      return {
        ...state,
        idctaInit: action.idctaInit,
      };
    case IDCTA_FAIL_RENDER:
      return {
        ...state,
        idctaInit: action.idctaInit,
      };
    case IDCTA_FLAGPOLE_RED:
      return {
        ...state,
        idctaFlagpoleRed: action.idctaFlagpoleRed,
      };
    default:
      return state;
  }
};

import pick from 'lodash/pick';
import { SET_AZ_PAGE_DATA } from './constants';

const initialState = {
  entity: '',
  letter: '',
  page: 1,
  pages: 1,
  promos: [],
  totalCount: 0,
  azCounts: {},
  metaDescription: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AZ_PAGE_DATA:
      return {
        ...state,
        ...pick(action, [
          'entity',
          'letter',
          'page',
          'pages',
          'promos',
          'totalCount',
          'azCounts',
          'metaDescription',
        ]),
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import LdJson from './LdJson';

const RecipeCarousel = ({ baseUrl, promos }) => {
  const recipes = Object.values(promos)
    .filter(({ url }) => url && url.includes('/recipes/'))
    .map(({ url }) => ({ url: url.substring(url.indexOf('/food')) }))
    .map(({ url }, position) => ({
      '@type': 'ListItem',
      position: position + 1,
      url: url.includes('http') ? url : `${baseUrl}${url}`,
    }));

  if (recipes.length === 0) return null;

  return <LdJson type="ItemList" markup={{ itemListElement: recipes }} />;
};

RecipeCarousel.defaultProps = {
  promos: [],
};

RecipeCarousel.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  promos: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string.isRequired })),
};

export default RecipeCarousel;

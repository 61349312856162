import { SET_ARTICLE_DATA } from './constants';

const initialState = {
  title: '',
  metaDescription: '',
  metaImage: undefined,
  modifiedDateTime: '',
  primaryCollection: {},
  secondaryCollection: {},
  blocksBody: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ARTICLE_DATA:
      return {
        ...state,
        blocksFailed: action.blocksFailed,
        title: action.title,
        metaDescription: action.metaDescription,
        metaImage: action.metaImage,
        modifiedDateTime: action.modifiedDateTime,
        primaryCollection: action.primaryCollection,
        secondaryCollection: action.secondaryCollection,
        blocksBody: action.blocksBody,
      };
    default:
      return state;
  }
};

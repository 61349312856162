import setDayOfYear from 'date-fns/set_day_of_year';
import format from 'date-fns/format';

export const formatOccasionDate = (startDate, endDate, now = new Date()) => {
  if (!startDate) return '';

  const startDateAsDate = setDayOfYear(now, startDate);
  const startDateString = format(startDateAsDate, 'D MMM');
  if (startDate === endDate) {
    return startDateString;
  }

  const endDateAsDate = setDayOfYear(now, endDate);
  const endDateString = format(endDateAsDate, 'D MMM');
  return `${startDateString} - ${endDateString}`;
};

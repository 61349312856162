import { SET_EPISODE_DATA } from './constants';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EPISODE_DATA:
      return {
        ...state,
        pid: action.pid,
        title: action.title,
        promoTitle: action.promoTitle,
        promoSubtitle: action.promoSubtitle,
        shortDescription: action.shortDescription,
        mediumDescription: action.mediumDescription,
        description: action.description,
        duration: action.duration,
        image: action.image,
        releaseDate: action.releaseDate,
        availableOnIPlayer: action.availableOnIPlayer,
        recipes: action.recipes,
        chefs: action.chefs,
        brandPid: action.brandPid,
        brandTitle: action.brandTitle,
        totalCount: action.totalCount,
        label: action.label,
        episodeNumber: action.episodeNumber,
        seriesNumber: action.seriesNumber,
      };
    default:
      return state;
  }
};
